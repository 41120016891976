import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

import AlertWarningTriangle from '@/components/atom/icon/AlertWarningTriangle';
import { Urls } from '@/constants/url';
import { Tablet } from '@/styles/layout';
import { ROUTES } from '@/types/common';
import { isWebView } from '@/utils/mobile/RNPlugins';

const NotFoundTemplate = () => {
  return (
    <Wrapper>
      <AlertWarningTriangle />
      <h1>페이지를 찾을 수 없습니다.</h1>
      <span>
        입력된 주소가 정확하지 않은 것 같아요.
        <br /> 상단 메뉴를 눌러 원하는 페이지로 이동하세요.
      </span>
      <Link href={!isWebView() ? Urls.Introduce : ROUTES.BOARD.HOME.path}>
        <Button>홈 화면으로 이동</Button>
      </Link>
    </Wrapper>
  );
};

export default NotFoundTemplate;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 184px 0 230px 0;
  svg {
    margin-bottom: 32px;
  }
  & > h1 {
    ${({ theme }) => theme.typography.UI28SB};
    color: ${({ theme }) => theme.color.gray900};
    margin-bottom: 10px;

    @media screen and (max-width: ${Tablet}) {
      ${({ theme }) => theme.typography.UI24SB};
    }
  }

  & > span {
    display: flex;
    justify-content: center;
    text-align: center;
    ${({ theme }) => theme.typography.UI16M};
    color: ${({ theme }) => theme.color.gray400};
    margin-bottom: 24px;

    @media screen and (max-width: ${Tablet}) {
      ${({ theme }) => theme.typography.UI14M};
    }
  }
`;

const Button = styled.button`
  ${({ theme }) => theme.typography.UI16M};
  display: flex;
  justify-content: center;
  padding: 10px 16px;
  width: fit-content;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray100};
  margin-bottom: 12px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.gray30};
  }
`;
